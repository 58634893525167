import React, { useState } from "react";
import styles from "./DataTable.module.css";
import {
  weth,
  wbtc,
  aave,
  link,
  crv,
  inch,
  mana,
  comp,
  ocean,
  usdc,
} from "../../images/image";
import SingleRow from "./SingleRow";
import axios from "axios";

const DataTable = () => {

  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Overview", "Portfolio"];
  const [data, setData] = useState([]);


  let api = "https://api.thegraph.com/subgraphs/name/civa/uns-positions"
  const getUsers = async () => {
    let query = `{
      users {
         address
         totalStaked
       referrer
       level1Count
       level2Count
       level1Earnings
       level2Earnings
       totalRefRewards
     }
     }`
    const response = await axios.post(api, { query })
    setData(response.data.data.users)
    console.log(response.data.data.users)
  }

  React.useEffect(() => {
    getUsers()
  }, [activeTab]);
  // const data = [
  //   [
  //     {
  //       rank: 1,
  //       address: "0x1234567890123456789012345678901234567890",
  //       levelOneCount: 10,
  //       levelTwoCount: 20,
  //       levelOneEarnings: 100,
  //       levelTwoEarnings: 200,
  //       totalRefRewards: 300
  //     }

  //   ],
  // ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.textContainer}>
        <h4 className={styles.title}>Referral Leaderboard
        </h4>
        {/* <p className={styles.text}>
          You have full control to manage your own account setting.
        </p> */}
      </div>
      {/* <div className={styles.tabContainer}>
        {tabs.map((el, i) => (
          <p
            key={i}
            onClick={() => setActiveTab(i)}
            className={[styles.tab, activeTab === i && styles.activeTab].join(
              " "
            )}
          >
            {el}
          </p>
        ))}
      </div> */}
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              {/* <th className={[styles.heading, styles.textAlignLeft].join(" ")}>
                Rank
              </th> */}
              <th className={styles.heading}>Rank</th>

              <th className={styles.heading}>Address</th>
              <th className={styles.heading}>Staked UNS</th>
              <th className={styles.heading}>Level 1</th>
              <th className={styles.heading}>Level 2</th>
              <th className={styles.heading}>Level 1 earnings</th>
              <th className={styles.heading}>Level 2 Earnings</th>
              <th className={styles.heading}>Total Earnings</th>
            </tr>
            {data.map((el, index) => (
              <SingleRow {...el} key={index} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
