import React from "react";
import formatNumber from "../../utils";
import styles from "./AmountInvested.module.css";
import { history, transferCard } from "../../../images/image";
import ChartComponent from "./Chart/Chart";
import { useTotalWithdrawRewards } from "../../../hash/useContractUniversal";
import { useTotalReferralEarned } from "../../../hash/useContractUniversal";
import { useAccount } from "wagmi";

const AmountInvested = () => {
  const { address } = useAccount();
  const totalWithdrawRewards = Number(useTotalWithdrawRewards())?.toFixed(2);
  const totalReferralEarned = Number(
    useTotalReferralEarned(process.env.REACT_APP_UNIVERSAL_CONTRACT, address)
  )?.toFixed(2);
  return (
    <div className={styles.amountInvested}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>Earnings Overview </h4>
        <div className={styles.amountContainer}>
          <div>
            <h4 className={styles.investedAmount}>
              {totalWithdrawRewards}{" "}
              <span className={styles.currency}>
                {process.env.REACT_APP_TOKEN_SYMBOL}
              </span>{" "}
            </h4>
            <p className={styles.text}>
              {process.env.REACT_APP_TOKEN_SYMBOL} Withdrawn
            </p>
          </div>
          <div>
            <h4 className={styles.investedAmount}>
              +{totalReferralEarned} {process.env.REACT_APP_TOKEN_SYMBOL}{" "}
            </h4>
            <p className={styles.text}>Referral Bonus</p>
          </div>
        </div>

        {/* <ChartComponent /> */}
      </div>

      <div className={styles.bottomBar}>
        <div className={styles.box}>
          <img
            onClick={() => {
              window.location.href = "/history";
            }}
            src={transferCard}
            alt="#"
            className={styles.icon}
          />
          <p
            onClick={() => {
              window.location.href = "/history";
            }}
            className={styles.bottomText}
          >
            Staking History
          </p>
        </div>
        {/* pus */}
      </div>
    </div>
  );
};

export default AmountInvested;
